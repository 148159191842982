<template>
  <div id="requirementPage">
    <div class="requireBody">
      <div class="requireContainer row flex">
        <!-- 左侧广告位 -->
        <Requirement></Requirement>
        <!-- 右侧内容 -->
        <div class="reContent">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
            <div class="sendTitle">
              <span></span>
              <label for class="f18">发布需求</label>
              海量专业服务商及时响应您的需求
            </div>
            <div class="reTop">
              <div class="p30 start flex align-center">
                <span class="one" style="width:90px;">我想要做:</span>
                  <el-form-item prop="title">
                    <el-input v-model="ruleForm.title" placeholder="请用一句话简要描述您的需求"></el-input>
                  </el-form-item>
              </div>

              <div class="p30 start flex align-center">
                <label>需求描述:</label>
                <el-form-item prop="requirementDesc">
                <textarea
                  class="text"
                  required
                  wrap
                  id
                  cols="30"
                  rows="10"
                  v-model="ruleForm.requirementDesc"
                  placeholder="请尽可能详细说明您想要做的产品或所需要的服务，以便我们为您推荐最合适的服务商"
                ></textarea>
                </el-form-item>
                <span class="textNum">0-500</span>
              </div>
            </div>
            <div class="requirementBox">
              <div class="p30 flex align-center">
                <span class="one">我的地址:</span>
                <div class="requirementWrap row">
                   <!-- <el-row class="address-header">
                    <el-col :span="4" class="address-all-btn" v-show="addressData.length > 4">
                      <span style="flex: 1;"></span>
                      <el-button type="text" @click="onToggleAddress">
                        <i class="el-icon-arrow-down"></i>
                        全部地址
                      </el-button>
                    </el-col>
                  </el-row> -->
                  <div class="address-content">
                      <Item
                        v-for="(item, index) in addressData"
                        :selected="item.selected"
                        :key="index"
                        :data="item"
                        @selected="val => onSelected(item, val)"
                        types="1"
                        @setDefault="onSetDefault"
                      />
                      <div @click="onAdd" class="address-item1 address-item_no">
                        <i class="add_address">+</i>
                        <p>添加地址</p>
                      </div>
                  </div>
                </div>
              </div>
                <div class="p30 start flex align-center pt">
                  <span class="one">我的预算:</span>
                  <el-form-item prop="budgetAmount">
                    <el-input v-model="ruleForm.budgetAmount" placeholder="您的预算金额(元)" style="width:220px;"></el-input>
                  </el-form-item>
                </div>
                <div class="p30 start flex align-center">
                  <span class="one">截止时间:</span>
                  <div class="block">
                    <el-form-item prop="endDate">
                    <el-date-picker
                      v-model="ruleForm.endDate"
                      type="datetime"
                      placeholder="选择日期时间">
                    </el-date-picker>
                  </el-form-item>
                  </div>
                </div>
                <div class="p30 start flex align-center">
                  <span class="telMan">联系人:</span>
                  <el-form-item prop="linkman">
                    <el-input v-model="ruleForm.linkman" placeholder="需求联系人" style="width:220px;"></el-input>
                  </el-form-item>
                </div>
                <div class="p30 start flex align-center">
                  <span class="one">联系号码:</span>
                  <el-form-item prop="mobile">
                    <el-input v-model="ruleForm.mobile" placeholder="需求联系人号码" style="width:220px;"></el-input>
                  </el-form-item>
                </div>
                <div class="submitFree" @click="submitForm('ruleForm')">免费发布需求</div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
     <Add ref="address" :params="addParmas"/>
  </div>
</template>
<script>
// import CitySelect from '@/components/common/citySelect';
import Requirement from "@/components/common/requirement";
import Add from '../order/address/add.vue'
import Item from '../order/address/Item.vue'
export default {
  components: { Requirement, Add, Item },
  data() {
    return {
      addParmas: {
        visible: false
      },
      addressData: [],
      ruleForm: {
        title:"", // 我想做的-标题
        linkman: "", // 联系人
        budgetAmount: "", // 我的预算
        mobile: "", // 联系号码
        endDate: "", // 截止时间
        chooseDate: "",  // 截止日期
        chooseTime: "",
        requirementDesc: "", // 需求描述
      },
      userConsigneeId: '',
      rules: {
        title:[
          { required: true, message: "请输入你想做的", trigger: "blur" },
        ],
        linkman: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z\d]+$/,
            message: "只能是数字，字母和汉字"
          },
          { min: 1, max: 20, message: "不能超过20个字符" }
        ],
        mobile: [
          { required: true, message: "请输入联系人号码", trigger: "blur" },
          { min: 1, max: 11, message: "长度11个字符", trigger: "blur" },
          { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: "请输入正确的手机号" }
        ],
        budgetAmount: [
          { required: true, message: "请输入预算金额", trigger: "blur" },
          { min: 1, max: 20, message: "不能超过20个字符" }
        ],
        chooseDate: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change"
          }
        ],
        chooseTime: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change"
          }
        ],
        endDate: [
          {
            type: "date",
            required: true,
            message: "请选择截止时间",
            trigger: "change"
          }
        ],
        requirementDesc:[
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ]
      }
    };
  },
  mounted(){
    this.getuserAddressData();
  },
  methods: {
    init (){
      this.getuserAddressData();
    },
    // 获取用户地址信息
    getuserAddressData(){
      this.$http.get('/userConsignee/getSiteByUserId',{params:{userId: 1}})
      .then(res =>{
        const { data } = res.data;
        this.addressData = data;
      })
    },
    // 弹出新增地址页面
     onAdd() {
      this.addParmas = {
        visible: true,
        confirm: val => {
          this.addParmas.visible = false
          this.getuserAddressData()
        }
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },

    // 提交用户需求信息
    submitForm(formName) {
      let bindInfo = {
        title: this.ruleForm.title,
        linkman: this.ruleForm.linkman,
        mobile: this.ruleForm.mobile,
        budgetAmount: this.ruleForm.budgetAmount,
        endDate: this.ruleForm.endDate,
        userConsigneeId: this.userConsigneeId,
        // endDate: this.ruleForm.chooseDate + this.ruleForm.chooseTime, // 截止时间
        requirementDesc: this.ruleForm.requirementDesc,
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http.post('/bidInfo/addBidInfo',bindInfo)
          .then(res => {
            this.$refs[formName].resetFields();
            console.log(res)
            // 发布成功后跳转需求成功页面
            this.$router.push({
              query:{
                id:res.data.data
              },
              path: '/requirementSuccess'
            })
          })
        } else {
          return false;
        }
      });
    },
    onToggleAddress() {
      this.togger = !this.togger
    },
    onSetDefault(item) {
      item.isDefult = true
      console.log('设为默认', item)
    },
    onSelected(item, state) {
      this.userConsigneeId = item.id
      this.addressData = this.addressData.map(i => {
        if (item.id !== i.id) {
          i.selected = false
        }
        return i
      })
      this.$set(item, 'selected', state)
      this.$emit('selected', item)
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/change.scss";
@import '../../assets/scss/common.scss';
@mixin icon($m) {
  $url: '../../assets/image/' + $m;
  @include bgImage($url);
}
// .align-center{
//   display: 	inline;
// }
.one {
  display: block;
  width: 100px;
  margin-bottom: 10px;
  padding-right: 19px;
  flex-shrink: 0;
}

/deep/ .el-form-item div {
  margin-left: 0 !important;
  margin-bottom: 0!important;
}

.telMan {
  padding-right: 38px;
  width:100px;
}

.pt {
  padding-top: 30px;
}

.reContent {
  flex: 1;
  background-color: #fff;
  padding: 30px 0px 0px 40px;
  box-sizing: border-box;
  .sendTitle {
    span {
      display: inline-block;
      width: 4px;
      height: 14px;
      margin-right: 8px;
      background-color: #d82229;
    }

    label {
      padding-right: 15px;
    }
  }

  .reTop {
    padding: 30px 110px 0px 14px;
    .el-input {
      width: 560px;
    }

    textarea {
      width: 560px;
      padding: 12px 15px;
      color: #989898;
      font-size: 13px;
      border: 1px solid #ddd;
      border-radius: 5px;
      &:focus {
        border-color: #d82229;
      }
    }

    .text {
      color: #989898;
      font-size: 14px;
    }
    .textNum {
      display: flex;
      flex-direction: column-reverse;
      height: 159px;
      margin-left: 14px;
      color: #666;
    }
  }
.address-content {
  width: 800px;
  // background: deepskyblue;
    margin-top: 10px;
    display: flex;
    // // // // flex-flow: row wrap;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-left: 0.5%;
    .address-item1{
      width: 240px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
   /deep/ .address-item{
      // width: 260px;
      width: 240px;
      height: 150px;
      flex-shrink: 0;
      border-radius: 4px;
      // background: orange;
      margin-right: 10px;
      
      cursor: pointer;
    }
    .address-item_no {
      background: none;
      text-align: center;
      color: #999;
      font-size: 16px;
      height: 150px;
      width: 240px;
      .add_address {
        background: #d82229;
        width: 44px;
        height: 44px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        display: block;
        font-style: normal;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 5px;
        border-radius: 50%;
        font-size: 35px;
      }
    }
  }
  .p30 {
    // padding-bottom: 30px;
    label {
      width: 90px;
      padding-right: 19px;
    }
    .requirementWrap {
      display: flex;
      flex-shrink: 0;
      .readdress {
        position: relative;
        left: 0;
        top: 0;
        width: 270px;
        height: 154px;
        margin-right: 24px;
        .imgBg {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 99;
          width: 270px;
          height: 154px;
        }
      }
      .reBox {
        position: absolute;
        top: 0;
        left: 0;
        color: #d82229;
        padding: 20px;
        .info {
          color: #999;
          padding-bottom: 20px;
          span {
            &:first-child {
              padding: 0 15px 0 55px;
              font-size: 12px;
            }
            cursor: pointer;
            &:last-child {
              color: #d82229;
            }
          }
        }
        .mobile {
          padding-bottom: 20px;
        }
        .l20 {
          line-height: 20px;
        }
      }
    }
  }
   /deep/ .el-dialog__footer {
    text-align: center!important;
  }
  .submitFree{
    width:  180px;
    height: 50px;
    background: #d82229;
    color: #fff;
    line-height: 50px;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
